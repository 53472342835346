type Init = {
  loading: boolean;
  error: any;
};

const initialState: Init = {
  loading: false,
  error: {},
};

export default function transactionPin(state = initialState, action: any) {
  switch (action.type) {
    case 'CLEAR_PIN_ERROR':
      return {
        ...state,
        error: {},
      };
    case 'SET_PIN_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_PIN_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}

export const setLoading = (payload: boolean) => ({
  type: 'SET_PIN_LOADING',
  payload,
});

export const setError = (payload: any) => ({
  type: 'SET_PIN_ERROR',
  payload,
});

export const clearError = () => ({
  type: 'CLEAR_PIN_ERROR',
});
