import request from '../../utils/request';
import {AppDispatch} from '../../store';
import {
  setCountries,
  setStates,
  setLoading,
  setFormLoading,
  signout,
  setCountryErrorDet,
  addNewState,
  addNewCountry,
  setStateError,
} from './actionCreators';

/**====================
 * GETTING COUNTRIES FROM SERVER
 * =======================
 */
const getCountries = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading(true));
    const response = await request.get('/geo/countries');

    dispatch(setCountries(response.data.data));
    dispatch(setLoading(false));

    return response.data;
  } catch (error) {
    dispatch(setLoading(false));

    if (error.response) {
      console.log(error.response.data.message);

      if (error.response.status === 401) dispatch(signout());
    } else if (error.request) {
      alert('Please Try again');
    } else {
      alert('Process Failed!');
    }
    dispatch(setLoading(false));
  }
};

/**====================
 * GETTING STATE IDS FROM SERVER
 * =======================
 */
export const getStateIds = (countryID: string) => async (
  dispatch: AppDispatch,
) => {
  try {
    dispatch(setLoading(true));
    const response = await request.get(`/geo/countries/${countryID}/states`);

    dispatch(setStates(response.data.data));
    dispatch(setLoading(false));

    return response.data;
  } catch (error) {
    dispatch(setLoading(false));

    if (error.response) {
      if (error.response.status === 401) dispatch(signout());
    } else if (error.request) {
      alert('Please Try again');
    } else {
      alert('Process Failed!');
    }
    dispatch(setLoading(false));
  }
};

export const addCountry = (data: any, openNotification: any) => async (
  dispatch: AppDispatch,
) => {
  try {
    dispatch(setFormLoading(true));
    const response = await request.post('/geo/countries', data);
    dispatch(addNewCountry(response.data.data));

    dispatch(setFormLoading(false));

    openNotification(`${response.data.data.name} created`);

    return response.data;
  } catch (error) {
    if (error.response) {
      openNotification(`${error.response.data.message}`);
      dispatch(setCountryErrorDet(error.response.data.errors));
      if (error.response.status === 401) dispatch(signout());
    } else if (error.request) {
      openNotification('Please Try again');
    } else {
      openNotification('Process Failed!');
    }

    dispatch(setFormLoading(false));
  }
};
export const addState = (
  data: any,
  country_uuid: string,
  openNotification: any,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setFormLoading(true));
    const response = await request.post(
      `geo/countries/${country_uuid}/states`,
      data,
    );

    dispatch(addNewState(response.data.data));

    dispatch(setFormLoading(false));
    openNotification(`${response.data.data.name} Added`);

    return response.data;
  } catch (error) {
    if (error.response) {
      openNotification(`${error.response.data.message}`);
      dispatch(setStateError(error.response.data.errors));
      if (error.response.status === 401) dispatch(signout());
    } else if (error.request) {
      openNotification('Please Try again');
    } else {
      openNotification('Process Failed!');
    }
    // console.log(error.config);
    dispatch(setFormLoading(false));
  }
};

export default getCountries;
