import React, {Suspense, lazy} from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import Loading from './components/loading/Loading';
import Login from './pages/login';
import Home from './pages/home';
import Dashboard from './compositions/dashboard';

const RecoverPassword = lazy(() => import('./pages/recover-password'));
const LogOut = lazy(() => import('./pages/login/LogOut'));
const OTPForm = lazy(() => import('./pages/otp'));
const ResetPassword = lazy(() => import('./pages/login/ResetPassword'));
const RequestNewPassword = lazy(() =>
  import('./pages/login/RequestNewPassword'),
);
const ProtectedRoutes = lazy(() => import('./components/private-routes'));

const App = () => (
  <Router>
    <ToastContainer />
    <Suspense fallback={<Loading />}>
      <Switch>
        <Route exact path="/">
          <ProtectedRoutes>
            <Home />
          </ProtectedRoutes>
        </Route>

        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/logout">
          <LogOut />
        </Route>
        <Route exact path="/recover-password">
          <RecoverPassword />
        </Route>
        <Route exact path="/otp">
          <OTPForm />
        </Route>
        <Route path="/dashboard">
          <Dashboard />
        </Route>
        <Route path="/onboarding/get-started">
          <ResetPassword />
        </Route>
        <Route path="/new-password">
          <RequestNewPassword />
        </Route>
      </Switch>
    </Suspense>
  </Router>
);

export default App;
