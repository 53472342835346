export const getUserWalletBalance = (user: any) => {
  return user.wallets && user.wallets.length !== 0 && user.wallets[0].amount;
};
export const getUserPurseBalance = (user: any) => {
  return user.wallets && user.wallets.length !== 0 && user.wallets[1].amount;
};

export const getUserRole = (data: any) => {
  return (
    data &&
    data.hasOwnProperty('roles') &&
    data.roles.length !== 0 &&
    data.roles[0]
  );
};
export const isAdmin = (data: any) => {
  return (
    data &&
    data.hasOwnProperty('roles') &&
    data.roles.length !== 0 &&
    data.roles[0].toLowerCase() === 'admin'
  );
};
export const isSuperAgent = (data: any) => {
  return (
    data &&
    data.hasOwnProperty('roles') &&
    data.roles.length !== 0 &&
    data.roles[0].toLowerCase() === 'super-agent'
  );
};
export const isAgent = (data: any) => {
  return (
    data &&
    data.hasOwnProperty('roles') &&
    data.roles.length !== 0 &&
    data.roles[0].toLowerCase() === 'agent'
  );
};
export const isSuperUser = (data: any) => {
  return (
    data &&
    data.hasOwnProperty('roles') &&
    data.roles.length !== 0 &&
    data.roles[0].toLowerCase() === 'superuser'
  );
};
export const isCustomer = (data: any) => {
  return (
    data &&
    data.hasOwnProperty('roles') &&
    data.roles.length !== 0 &&
    data.roles[0].toLowerCase() === 'customer'
  );
};

// LOGGED IN USER

export const getLoggedInUser = (currUser: any) => {
  return (
    currUser &&
    currUser.roles &&
    currUser.roles.length !== 0 &&
    currUser.roles[0]
  );
};

export const isLoggedInUserAdmin = (currUser: any) => {
  return (
    currUser &&
    currUser.roles &&
    currUser.roles.length !== 0 &&
    currUser.roles[0].toLowerCase() === 'admin'
  );
};
export const isLoggedInUserAgent = (currUser: any) => {
  return (
    currUser &&
    currUser.roles &&
    currUser.roles.length !== 0 &&
    currUser.roles[0].toLowerCase() === 'agent'
  );
};
export const isLoggedInUserSuperAgent = (currUser: any) => {
  return (
    currUser &&
    currUser.roles &&
    currUser.roles.length !== 0 &&
    currUser.roles[0].toLowerCase() === 'super-agent'
  );
};
export const isLoggedInUserCustomer = (currUser: any) => {
  return (
    currUser &&
    currUser.roles &&
    currUser.roles.length !== 0 &&
    currUser.roles[0].toLowerCase() === 'customer'
  );
};

//Another way of checking roles

export const isUserAdmin = (profile: any) => {
  return (
    profile &&
    profile.roles &&
    profile.roles.length !== 0 &&
    profile.roles[0].name.toLowerCase() === 'admin'
  );
};
export const isUserAgent = (profile: any) => {
  return (
    profile &&
    profile.roles &&
    profile.roles.length !== 0 &&
    profile.roles[0].name.toLowerCase() === 'agent'
  );
};
export const isUserCustomer = (profile: any) => {
  return (
    profile &&
    profile.roles &&
    profile.roles.length !== 0 &&
    profile.roles[0].name.toLowerCase() === 'customer'
  );
};
export const isUserSuperAgent = (profile: any) => {
  return (
    profile &&
    profile.roles &&
    profile.roles.length !== 0 &&
    profile.roles[0].name.toLowerCase() === 'super-agent'
  );
};

export const getRole = (profile: any) => {
  return (
    profile &&
    profile.roles &&
    profile.roles.length !== 0 &&
    profile.roles[0].name
  );
};
