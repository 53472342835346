import React, {useEffect} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Formik, Field} from 'formik';
import * as Yup from 'yup';
import BeatLoader from 'react-spinners/BeatLoader';
import {connect, useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {Alert} from 'reactstrap';

import {LoginFormValues} from '../../ts/types';
import login from '../../redux/actions/login.action';
import getCountries from '../../redux/actions/countries.action';
import logo from '../../static/images/logo-light.png';
import {clearLoginError} from '../../redux/actions/actionCreators';
import {setLoginLoading} from '../../redux/actions/actionCreators';
import './login.css';

const App = ({
  history,
  login,
  getCountries,
  countries,
  error,
  loading,
}: {
  history: any;
  login: any;
  getCountries: any;
  countries: any;
  error: string;
  loading: boolean;
}) => {
  const dispatch = useDispatch();
  const openNotification = (mess: string) => {
    return toast(mess);
  };
  useEffect(() => {
    dispatch(clearLoginError());
    dispatch(setLoginLoading(false));
    getCountries(openNotification);
  }, [getCountries, dispatch]);

  const initialValues: LoginFormValues = {
    identity: '',
    password: '',
    country: 'NG',
  };

  return (
    <div className="login-body bg-dark bg-koloo">
      <div className="login-page">
        <div className="text-center m-b-40">
          <img src={logo} alt="Koloo" className="img-fluid logo" />
        </div>

        <div className="card account-card mx-4">
          <div className="account-card-content">
            <p className="text-center text-muted">
              Sign in to your Koloó account.
            </p>

            <Formik
              initialValues={initialValues}
              validationSchema={Yup.object({
                identity: Yup.string()
                  .max(25, 'Must be 25 characters or less')
                  .min(2, 'Must be more than one character')
                  .required('Please provide username'),
                password: Yup.string().required('Please provide password'),
                country: Yup.string()
                  .max(15, 'Must be 15 characters or less')
                  .min(2, 'must 6 characters or more')
                  .required('Please provide country'),
              })}
              onSubmit={async (values: LoginFormValues): Promise<void> => {
                const navigateToDashboard = () => history.push('/dashboard');

                await login(values, navigateToDashboard, openNotification);
                getCountries(openNotification);
              }}
            >
              {formik => (
                <form
                  className="form-horizontal m-t-30"
                  onSubmit={formik.handleSubmit}
                >
                  {error && error !== '' && (
                    <Alert color="danger">{error}</Alert>
                  )}
                  <div className="form-group">
                    <label htmlFor="identity">Phone Number</label>
                    <Field
                      id="identity"
                      type="text"
                      name="identity"
                      className="form-control"
                      placeholder="Phone number"
                    />
                    {formik.touched.identity && formik.errors.identity ? (
                      <small style={{color: 'red'}}>
                        {formik.errors.identity}
                      </small>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <Field
                      id="password"
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Enter password"
                    />
                    {formik.touched.password && formik.errors.password ? (
                      <small style={{color: 'red'}}>
                        {formik.errors.password}
                      </small>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <label htmlFor="country">Country</label>
                    <Field
                      as="select"
                      type="country"
                      name="country"
                      className="form-control"
                      placeholder="e.g. 'NG' or 'GH'"
                    >
                      {countries &&
                        countries.length > 0 &&
                        countries.map((country: any) => (
                          <option key={country.id} value={country.code}>
                            {country.name}
                          </option>
                        ))}
                    </Field>
                    {formik.touched.country && formik.errors.country ? (
                      <small style={{color: 'red'}}>
                        {formik.errors.country}
                      </small>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <button
                      className="btn btn-primary btn-block btn-lg"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? (
                        <BeatLoader size={5} color="#fff" />
                      ) : (
                        'Log In'
                      )}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>

        <div className="m-t-40 text-center">
          <div>
            <Link className="text-white" to="/new-password">
              <i className="mdi mdi-lock"></i> Forgot your password?
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  login,
  getCountries,
};

const mapStateToProps = (state: any) => {
  const {countries, user} = state;

  return {
    countries: countries.data,
    error: user.loginError,
    loading: user.loginLoading,
  };
};

type ReduxType = typeof mapDispatchToProps.login;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
