type Init = {
  data: any;
  loading: boolean;
  error: any;
};

const initialState: Init = {
  data: {},
  loading: false,
  error: {},
};

export default function allSavings(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_ALL_SAVINGS_DATA':
      return {
        ...state,
        data: action.payload,
      };
    case 'CLEAR_ALL_SAVINGS_DATA_ERROR':
      return {
        ...state,
        error: {},
      };
    case 'SET_ALL_SAVINGS_DATA_ERROR':
      return {
        ...state,
        error: action.payload,
      };

    case 'SET_ALL_SAVINGS_DATA_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}

export const setData = (payload: any) => ({
  type: 'SET_ALL_SAVINGS_DATA',
  payload,
});
export const setLoading = (payload: boolean) => ({
  type: 'SET_ALL_SAVINGS_DATA_LOADING',
  payload,
});

export const setError = (payload: any) => ({
  type: 'SET_ALL_SAVINGS_DATA_ERROR',
  payload,
});

export const clearError = () => ({
  type: 'CLEAR_ALL_SAVINGS_DATA_ERROR',
});
