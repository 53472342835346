import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash.throttle';
import rootReducer from './rootReducer';
import {composeWithDevTools} from 'redux-devtools-extension';
import {loadState, saveState} from './utils/localStorage';

const middleware = applyMiddleware(thunk);
const enhancer = composeWithDevTools(middleware);
const persistedState = loadState();
const store = createStore(rootReducer, persistedState, enhancer);

store.subscribe(
  throttle(() => {
    saveState({
      user: store.getState().user,
      authentication: store.getState().authentication,
      countries: store.getState().countries,
      savings: store.getState().savings,
      savingCycles: store.getState().savingCycles,
      settings: store.getState().settings,
      superAgents: store.getState().superAgents,
    });
  }, 1000),
);

export type AppDispatch = typeof store.dispatch;

export default store;
