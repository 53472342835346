import React from 'react';

import {Row, Col, Card, CardBody} from 'reactstrap';
import errorImg from './static/images/errorr2.png';

// eslint-disable-next-line react/prop-types
function ErrorFallback({error, resetErrorBoundary}) {
  // eslint-disable-next-line react/prop-types
  // console.log(error.message);
  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-5">
        <div className="container">
          <Row className="justify-content-center">
            <Col md="8" lg="6" xl="5">
              <Card className="shadow-none overflow-hidden">
                <CardBody>
                  <div className="text-center">
                    <h1 className="display-2 font-weight-normal mt-0">500!</h1>
                    <h4>Something went wrong:</h4>
                    <p className="text-muted mb-5">
                      {/* eslint-disable-next-line react/prop-types*/}
                      {error.message}
                      <br />
                      Please click button below
                    </p>
                  </div>
                </CardBody>
                <div>
                  <img
                    src={errorImg}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
                <button
                  className="btn btn-primary"
                  onClick={resetErrorBoundary}
                >
                  Go back to Dashbord
                </button>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ErrorFallback;
