type Menu = {
  [key: string]: string;
};

type x = {
  [key: string]: [
    {
      [key: string]: string;
    },
  ];
};

export const adminItems: any = [
  {Home: '/dashboard'},
  {Transactions: '/dashboard/transactions?page=1&range=30'},
  {Accounts: '/dashboard/accounts?page=1&range=30'},
  {'Register Agent': '/dashboard/register-agent'},
  {Payouts: '/dashboard/accounts/commission/payouts'},
  {'Account Settings': '/dashboard/set-transaction-pin'},

  {
    Admin: [
      {'Manage Countries': '/dashboard/admin/countries'},
      {'Saving Cycles': '/dashboard/admin/saving-settings'},
      {'Manage Banks': '/dashboard/admin/banks'},
      {'Pending Applications': '/dashboard/admin/approvals'},
      {'Settings': '/dashboard/admin/settings'},
      {'All Savings': '/dashboard/admin/all-savings?page=1&range=30'},
    ],
  },
];

export const customerItems: Menu[] = [
  {Home: '/dashboard'},
  {Transactions: '/dashboard/transactions?page=1&range=30'},
  {'Account Settings': '/dashboard/set-transaction-pin'},
];

export const superAgentItems = [
  {Home: '/dashboard'},
  {Transactions: '/dashboard/transactions?page=1&range=30'},
  {Accounts: '/dashboard/accounts?page=1&range=30'},
  {'Register Agent': '/dashboard/register-agent'},
  {'Create Customer': '/dashboard/customer'},
  {Payouts: '/dashboard/accounts/commission/payouts'},
  {'Account Settings': '/dashboard/set-transaction-pin'},
];

export const agentItems = [
  {Home: '/dashboard'},
  {Transactions: '/dashboard/transactions?page=1&range=30'},
  {Accounts: '/dashboard/accounts?page=1&range=30'},
  {'Create Customer': '/dashboard/customer'},
  {Payouts: '/dashboard/accounts/commission/payouts'},
  {'Account Settings': '/dashboard/set-transaction-pin'},
];

export const superUser = [{Accounts: '/dashboard/accounts?page=1'}];
