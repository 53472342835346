const initialState = {
  loading: false,
  data: {},
  errorDet: {},
  account: {},
  singleAccLoading: false,
  stats: {},
  statsError: {},
};

export default function accounts(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_ACCOUNTS':
      return {
        ...state,
        errorDet: {},
        data: action.payload,
      };
    case 'SET_STATS':
      return {
        ...state,
        errorDet: {},
        stats: action.payload,
      };

    // rdgfhj
    case 'SET_STATS_ERROR':
      return {
        ...state,
        errorDet: {},
        statsError: action.payload,
      };
    case 'SET_SINGLE_ACCOUNT':
      return {
        ...state,
        errorDet: {},
        account: action.payload,
      };

    case 'SINGLE_ACCOUNT_LOADING':
      return {
        ...state,
        singleAccLoading: action.payload,
      };
    case 'ACCOUNTS_LOADING':
      return {
        ...state,
        loading: action.payload,
      };

    case 'CLEAR_ACCOUNTS_ERROR':
      return {
        ...state,
        errorDet: {},
        statsError: {},
      };

    case 'SET_ACCOUNTS_ERROR':
      return {
        ...state,
        errorDet: action.payload,
      };
    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
