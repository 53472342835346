import React from 'react';
import {Link, withRouter, RouteComponentProps} from 'react-router-dom';
import {useDispatch} from 'react-redux';

import {signout} from '../../../redux/actions/actionCreators';

const App = ({history}: RouteComponentProps) => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(signout());
    return history.push('/logout');
  };

  return (
    <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
      <Link
        className="text-danger nav-link waves-effect"
        to="#"
        onClick={handleLogout}
      >
        <i className="fas fa-power-off fa-lg"></i>
      </Link>
    </li>
  );
};

export default withRouter(App);
