import React, {useState} from 'react';
import BeatLoader from 'react-spinners/BeatLoader';
import {connect} from 'react-redux';
import {toast} from 'react-toastify';
import {withRouter, RouteComponentProps} from 'react-router-dom';

import {getCustomerSaving} from '../../../redux/actions/savings.action';
import {useLayoutContext} from '../../../context/layout';
import './search.css';

interface Props extends RouteComponentProps<any> {
  loading?: boolean;
  getCustomerSaving: any;
}

const SearchCustomer = (props: Props) => {
  const {loading, getCustomerSaving, history} = props;
  const [identity, setIdentity] = useState('');

  const {isMobile} = useLayoutContext();

  const openNotification = (mess: string) => {
    return toast(mess);
  };

  const handleChange = (e: any) => {
    const {value} = e.target;
    setIdentity(value);
  };

  const navigate = (direction: string) => history.push(`/${direction}`);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    await getCustomerSaving(identity, openNotification, navigate);
  };

  return (
    <li className="dropdown notification-list list-inline-item koloo-search search-li">
      <form
        role="search"
        className="app-search search-form"
        onSubmit={handleSubmit}
        style={{width: '100%', padding: '1em'}}
      >
        <div className="form-group mb-0 search-div" style={{width: '100%'}}>
          <input
            type="text"
            style={{width: `${isMobile ? '84%' : '100%'}`}}
            className="form-control search-field"
            placeholder="Enter Phone number or Koloo ID"
            name="identity"
            onChange={handleChange}
            value={identity}
          />
          <button
            type="submit"
            disabled={loading}
            style={{width: '0'}}
            className="search-btn"
          >
            {loading ? (
              <BeatLoader size={2} color="#fff" />
            ) : (
              <i className="fa fa-search"></i>
            )}
          </button>
        </div>
      </form>
    </li>
  );
};

const mapStateToProps = (state: any) => {
  const {savings} = state;
  return {
    loading: savings.getLoading,
  };
};
const mapDispatchToProps = {
  getCustomerSaving,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchCustomer),
);
