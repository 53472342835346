import request from '../../utils/request';
import {AppDispatch} from '../../store';
import {
  signout,
  setSaving,
  clearSavingsError,
  getSavings,
  setSavingLoading,
  setAllSavingsLoading,
  setSavingsError,
  setContribution,
  setContributionError,
  setContributionLoading,
  setAllContributions,
  setContributionsLoading,
  setContributionsError,
  setSavingsErrorMsg,
} from './actionCreators';

/**====================
 * CREATE SAVINGS
 * =======================
 */
const createSaving = (
  data: any,
  openSuccessAlert: any,
  openErrorAlert: any,
  openOtpModal: any,
  closeOTPModal: any,
  clearForm: any,
  setShowTimer: any,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setSavingLoading(true));
    const response = await request.post('/savings', data);
    dispatch(setSaving(response.data.data));
    dispatch(setSavingLoading(false));
    await closeOTPModal();

    openSuccessAlert(
      'Success!',
      `Savings Created for ${response.data.data.cycle.title}!`,
    );

    clearForm();
    dispatch(clearSavingsError());

    return response.data;
  } catch (error) {
    dispatch(setSavingLoading(false));

    if (error.response) {
      if (error.response.status === 401) {
        if (error.response.data.otp_required) {
          dispatch(setSavingsErrorMsg(error.response.data));
          openOtpModal();

          if (error.response.data.message === 'Invalid code entered.') {
            setShowTimer(true);
          }

          return;
        }

        dispatch(signout());
        return;
      }
      if (error.response.data.errors) {
        dispatch(setSavingsError(error.response.data.errors));
        closeOTPModal();
        openErrorAlert('Process Failed!', error.response.data.message);
        return;
      }

      closeOTPModal();
      openErrorAlert('Process Failed!', error.response.data.message);
    } else if (error.request) {
      openErrorAlert('Process Failed!', 'Please Try again');
    } else {
      openErrorAlert('Process Failed!', 'Please Try again');
    }
    dispatch(setSavingLoading(false));
  }
};

/**====================
 * GET SAVINGS
 * =======================
 */

export const getCustomerSaving = (
  q: string,
  openNotification: any,
  navigate: any,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setAllSavingsLoading(true));
    const response = await request.get('/savings', {
      params: {
        q,
      },
    });

    dispatch(getSavings(response.data.data));
    dispatch(setAllSavingsLoading(false));
    dispatch(clearSavingsError());

    if (response.data.message === 'Savings') {
      navigate(`dashboard/saving/create/${q}`);
    }

    return response.data;
  } catch (error) {
    dispatch(setAllSavingsLoading(false));

    if (error.response) {
      openNotification(error.response.data.message);

      if (error.response.status === 401) return dispatch(signout());
      if (error.response.data.message === 'User not found.')
        return navigate('dashboard/customer');

      dispatch(setSavingsError(error.response.data.errors));
    } else if (error.request) {
      openNotification('Please Try again');
    } else {
      openNotification('Please Try again');
    }
    dispatch(setAllSavingsLoading(false));
  }
};

/**====================
 * CONTRIBUTE
 * =======================
 */

export const contribute = (
  data: any,
  id: string,
  openSuccessAlert: any,
  openErrorAlert: any,
  setShowOtpField: any,
  clearForm: any,
  setShowTimer: any,
  closeContributeModal?: any,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setContributionLoading(true));
    const response = await request.post(`/savings/${id}/contribute`, data);

    dispatch(setContribution(response.data.data));
    dispatch(setContributionLoading(false));

    if (closeContributeModal) {
      closeContributeModal();
    }

    openSuccessAlert('Success!', 'Contribution Created');

    dispatch(clearSavingsError());
    clearForm();

    return response.data;
  } catch (error) {
    dispatch(setContributionLoading(false));

    if (error.response) {
      if (error.response.status === 401) {
        if (error.response.data.otp_required) {
          dispatch(setSavingsErrorMsg(error.response.data));
          setShowOtpField(true);

          if (error.response.data.message === 'Invalid code entered.') {
            setShowTimer(true);
          }
          return;
        }

        dispatch(signout());
        return;
      }
      if (error.response.data.errors)
        dispatch(setContributionError(error.response.data.errors));
      closeContributeModal && closeContributeModal();
      openErrorAlert('Process Failed!', error.response.data.message);
    } else if (error.request) {
      closeContributeModal && closeContributeModal();
      openErrorAlert('Process Failed!', 'Please Try again');
    } else {
      closeContributeModal && closeContributeModal();
      openErrorAlert('Process Failed!', 'Please Try again');
    }

    dispatch(setContributionLoading(false));
  }
};
export const getContributions = (id: string) => async (
  dispatch: AppDispatch,
) => {
  try {
    dispatch(setContributionsLoading(true));
    const response = await request.get(`/savings/${id}/contribute`);
    dispatch(setAllContributions(response.data.data));

    dispatch(clearSavingsError());
    dispatch(setContributionsLoading(false));

    return response.data;
  } catch (error) {
    dispatch(setContributionsLoading(false));

    if (error.response) {
      if (error.response.status === 401) dispatch(signout());
      dispatch(setContributionsError(error.response.data.errors));
    } else if (error.request) {
      console.log('Please Try again');
    } else {
      console.log('Please Try again');
    }
    dispatch(setContributionsLoading(false));
  }
};

export default createSaving;
