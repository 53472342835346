import React, {useState, useRef} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {withRouter, Link, useHistory} from 'react-router-dom';
import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MetisMenu from 'metismenujs';
import {useSelector, useDispatch} from 'react-redux';
import useOnClickOutside from 'use-onclickoutside';

import {
  adminItems,
  customerItems,
  superAgentItems,
  superUser,
  agentItems,
} from './statics/dashboard-menu-items';
import MenuItems from './MenuItems';
import Collapsible from './Collapsible';
import {useLayoutContext} from '../../context/layout';
import {signout} from '../../redux/actions/actionCreators';
import {isAdmin, isSuperAgent, isAgent, isCustomer} from '../../utils/';

const SideNav = () => {
  const {user} = useSelector(
    (user: {user: {loading: boolean; data: any}}) => user,
  );

  const [logOutActive, setLogOutActive] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const sideBarRef = useRef<any>(null);

  const [currIndex, setCurrIndex] = useState(0);

  const {isMobile, closeSidebar} = useLayoutContext();

  const ifMobileCloseOnClickOutside = () => {
    if (isMobile) {
      closeSidebar();
    }
  };

  useOnClickOutside(sideBarRef, ifMobileCloseOnClickOutside);

  const handleClick = (index: number) => {
    if (isMobile) {
      closeSidebar();
    }

    setCurrIndex(index);
  };

  const list: any[] = isAdmin(user.data)
    ? adminItems
    : isSuperAgent(user.data)
    ? superAgentItems
    : isAgent(user.data)
    ? agentItems
    : isCustomer(user.data)
    ? customerItems
    : superUser;

  const handleLogout = () => {
    setLogOutActive(true);
    dispatch(signout());
    return history.push('/logout');
  };

  return (
    <div id="sidebar-menu" ref={sideBarRef}>
      <ul className="metismenu" id="side-menu">
        <li className="menu-title">Main</li>

        {list.map((item, index) => {
          let val = Object.values(item)[0];
          let key = Object.keys(item)[0];
          if (typeof val === 'string') {
            return (
              <MenuItems
                key={key}
                item={item}
                currIndex={currIndex}
                handleClick={handleClick}
                index={index}
              />
            );
          } else {
            return (
              <Collapsible
                key={key}
                item={item}
                index={index}
                currIndex={currIndex}
                setCurrIndex={setCurrIndex}
              />
            );
          }
        })}
      </ul>
      <ul className="metismenu" id="side-menu" style={{marginTop: '9em'}}>
        {isMobile ? (
          <>
            <div
              className="dropdown-divider"
              style={{borderColor: '#85a8ce', margin: '0 1em'}}
            ></div>
            <li className={logOutActive ? 'mm-active' : ''}>
              <Link
                to="#"
                className={`waves-effect ${logOutActive ? 'mm-active' : ''}`}
                onClick={handleLogout}
                style={{color: '#85a8ce'}}
              >
                <i className="fas fa-power-off fa-lg"></i>
                <span> Logout </span>
              </Link>
            </li>
          </>
        ) : null}
      </ul>
    </div>
  );
};

type MyState = {};

class App extends React.Component<SBProps, MyState> {
  componentDidMount(): void {
    new MetisMenu('#side-menu');

    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu')!;
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown(item: any) {
    item.classList.add('mm-active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active'); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  }

  render() {
    return (
      <React.Fragment>
        <div className="left side-menu">
          {this.props.toggle ? (
            <PerfectScrollbar>
              <SideNav />
            </PerfectScrollbar>
          ) : (
            <SideNav />
          )}
          <div className="clearfix"></div>
        </div>
      </React.Fragment>
    );
  }
}

export interface SBProps extends RouteComponentProps<{name: string}> {
  // TS-SideBar
  toggle: boolean;
}

export default withRouter(App);
