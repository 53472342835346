const initialState = {
  loading: false,
  data: {},
  errorDet: {},
  errorMsg: '',
};

export default function debitCredit(state = initialState, action: any) {
  switch (action.type) {
    case 'DEBIT_CREDIT_RESPONSE':
      return {
        ...state,
        errorDet: {},
        data: action.payload,
      };

    case 'DEBIT_CREDIT_LOADING':
      return {
        ...state,
        loading: action.payload,
      };

    case 'CLEAR_DC_ERROR':
      return {
        ...state,
        errorDet: {},
        errorMsg: '',
      };

    case 'DEBIT_CREDIT_ERROR':
      return {
        ...state,
        errorDet: action.payload,
        errorMsg: {},
      };
    case 'DEBIT_CREDIT_ERROR_MSG':
      return {
        ...state,
        errorMsg: action.payload,
        errorDet: {},
      };

    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
