type State = {
  name: string;
  id: string;
};

type Init = {
  data: any;
  states: State[];
  loading: boolean;
  formLoading: boolean;
  countryErr: any;
  stateErr: any;
};

const initialState: Init = {
  data: [],
  states: [],
  loading: false,
  formLoading: false,
  countryErr: {},
  stateErr: {},
};

export default function countries(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_COUNTRIES':
      return {
        ...state,
        data: action.payload,
        countryErr: {},
        stateErr: {},
      };
    case 'SET_STATES':
      return {
        ...state,
        states: action.payload,
        countryErr: {},
        stateErr: {},
      };
    case 'ADD_NEW_STATE':
      return {
        ...state,
        states: [...state.states, action.payload],
        countryErr: {},
        stateErr: {},
      };
    case 'ADD_NEW_COUNTRY':
      return {
        ...state,
        data: [...state.data, action.payload],
        countryErr: {},
        stateErr: {},
      };
    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_FORM_LOADING':
      return {
        ...state,
        formLoading: action.payload,
        loading: false,
      };
    case 'SET_COUNTRY_ERROR':
      return {
        ...state,
        countryErr: action.payload,
      };
    case 'SET_STATE_ERROR':
      return {
        ...state,
        stateErr: action.payload,
      };
    case 'LOG_OUT':
      return initialState;
    default:
      return initialState;
  }
}
