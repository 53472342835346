import React, {lazy} from 'react';
import {Route} from 'react-router-dom';

const Transactions = lazy(() => import('../../pages/transactions/'));
const Accounts = lazy(() => import('../../pages/accounts/'));
const Profile = lazy(() => import('../../pages/accounts/Profile'));
const RegisterAgent = lazy(() => import('../../pages/create-agent'));
const EditAgent = lazy(() => import('../../pages/create-agent/EditAgent'));
const EditCustomer = lazy(() => import('../../pages/create-user/EditCustomer'));
const UploadDoc = lazy(() =>
  import('../../pages/register-agent/upload-document/Agreement'),
);
const TransactionPin = lazy(() => import('../../pages/transaction-pin/'));

const CreateUser = lazy(() => import('../../pages/create-user'));
const ChangePassword = lazy(() => import('../../pages/change-password'));
const Payout = lazy(() => import('../../pages/payout'));
const SavingSettings = lazy(() => import('../../pages/saving-settings'));
const EditSavingsCycle = lazy(() =>
  import('../../pages/saving-settings/EditSavingCycle'),
);
const CreateSaving = lazy(() => import('../../pages/savings/CreateSavings2'));
const Contributions = lazy(() => import('../../pages/savings/Contributions'));
const AddCountry = lazy(() => import('../../pages/admin/CountryTable'));
const AddState = lazy(() => import('../../pages/admin/StateTable'));
const PendingApprovals = lazy(() =>
  import('../../pages/admin/approvals/PendingApprovals'),
);
const Banks = lazy(() => import('../../pages/admin/banks/BanksTable'));
const Settings = lazy(() => import('../../pages/admin/settings/'));
const SingleTransactions = lazy(() =>
  import('../../pages/transactions/SingleTransactions'),
);

const AllSavings = lazy(() => import('../../pages/admin/ViewAllSavings'));
const ContributionPage = lazy(() =>
  import('../../pages/savings/contribution-page/ContributionPage'),
);

const AdminDashboard = (props: {path: string}) => {
  const {path} = props;
  return (
    <>
      <Route exact path={`${path}/transactions`}>
        <Transactions />
      </Route>
      <Route exact path={`${path}/accounts`}>
        <Accounts />
      </Route>

      <Route exact path={`${path}/saving/create/:identity`}>
        <CreateSaving />
      </Route>
      <Route exact path={`${path}/saving/contributions/:id`}>
        <Contributions />
      </Route>
      <Route exact path={`${path}/register-agent`}>
        <RegisterAgent />
      </Route>
      <Route exact path={`${path}/register-agent/upload-doc/:agntID`}>
        <UploadDoc />
      </Route>

      <Route exact path={`${path}/customer`}>
        <CreateUser />
      </Route>
      <Route exact path={`${path}/admin/saving-settings`}>
        <SavingSettings />
      </Route>
      <Route exact path={`${path}/admin/saving-settings/:cycleDetails/:id`}>
        <EditSavingsCycle />
      </Route>
      <Route exact path={`${path}/admin/approvals`}>
        <PendingApprovals />
      </Route>
      <Route exact path={`${path}/change-password/:userId`}>
        <ChangePassword />
      </Route>
      <Route exact path={`${path}/profile/:userId`}>
        <Profile />
      </Route>
      <Route exact path={`${path}/admin/countries`}>
        <AddCountry />
      </Route>
      <Route exact path={`${path}/admin/countries/:countryId/:countryName`}>
        <AddState />
      </Route>
      <Route exact path={`${path}/accounts/commission/payouts`}>
        <Payout />
      </Route>
      <Route exact path={`${path}/admin/banks`}>
        <Banks />
      </Route>
      <Route exact path={`${path}/admin/settings`}>
        <Settings />
      </Route>
      <Route exact path={`${path}/transactions/:userIdentity`}>
        <SingleTransactions />
      </Route>
      <Route exact path={`${path}/edit-agent/:agentId`}>
        <EditAgent />
      </Route>
      <Route exact path={`${path}/edit-customer/:customerId`}>
        <EditCustomer />
      </Route>
      <Route exact path={`${path}/admin/all-savings`}>
        <AllSavings />
      </Route>
      <Route exact path={`${path}/set-transaction-pin`}>
        <TransactionPin />
      </Route>
      <Route
        exact
        path={`${path}/savings/contribute/:savingsId/:cycleTitle/:customerName`}
      >
        <ContributionPage />
      </Route>
    </>
  );
};

export default AdminDashboard;
