export const initialState = {
  loading: false,
  loginLoading: false,
  data: {},
  profile: {},
  loginError: '',
  otpError: '',
  singleProfile: {},
  passwordResetError: {},
  reqNewPasswordError: {},
  reqNewPasswordLoading: false,
};

export default function user(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        data: {...state.data, ...action.payload},
        loginError: '',
        otpError: '',
      };
    case 'SET_PIN':
      return {
        ...state,
        data: {...state.data, has_transaction_pin: action.payload},
      };
    case 'SET_PROFILE':
      return {
        ...state,
        profile: action.payload,
      };
    case 'SET_SINGLE_PROFILE':
      return {
        ...state,
        singleProfile: action.payload,
      };
    case 'SET_LOGIN_ERROR':
      return {
        ...state,
        loginError: action.payload,
      };
    case 'SET_PASSWORD_REST_ERROR':
      return {
        ...state,
        passwordResetError: action.payload,
      };
    case 'SET_REQ_NEW_PASSWORD_ERROR':
      return {
        ...state,
        reqNewPasswordError: action.payload,
      };
    case 'SET_REQ_NEW_PASSWORD_LOADING':
      return {
        ...state,
        reqNewPasswordLoading: action.payload,
      };
    case 'CLEAR_LOGIN_ERROR':
      return {
        ...state,
        loginError: '',
      };
    case 'SET_OTP_ERROR':
      return {
        ...state,
        otpError: action.payload,
      };

    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_LOGIN_LOADING':
      return {
        ...state,
        loginLoading: action.payload,
      };
    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}

export const setPin = (payload: boolean) => ({
  type: 'SET_PIN',
  payload,
});
