import React, {lazy} from 'react';
import {Route} from 'react-router-dom';


const Transactions = lazy(() => import('../../pages/transactions/'));
const Cashout = lazy(() => import('../../pages/cashout'));
const TransactionPin = lazy(() => import('../../pages/transaction-pin/'));

const CustomerDashboard = (props: {path: string}) => {
  const {path} = props;

  return (
    <>
      <Route exact path={`${path}/transactions`}>
        <Transactions />
      </Route>
      <Route exact path={`${path}/user/payout`}>
        <Cashout />
      </Route>
      <Route exact path={`${path}/set-transaction-pin`}>
        <TransactionPin />
      </Route>
    </>
  );
};

export default CustomerDashboard;
