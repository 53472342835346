const initialState = {
  data: {},
  loading: false,
  getLoading: false,
  errorMsg: '',
  errorDet: {},
};

export default function settings(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_SETTINGS':
      return {
        ...state,
        data: action.payload,
        errorMsg: '',
        errorDet: {},
      };
    case 'SET_SETTINGS_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_GET_SETTINGS_LOADING':
      return {
        ...state,
        getLoading: action.payload,
      };
    case 'SET_SETTINGS_ERRORS':
      return {
        ...state,
        errorDet: action.payload,
      };
    case 'SET_SETTINGS_ERROR_MSG':
      return {
        ...state,
        errorMsg: action.payload,
      };
    case 'CLEAR_SETTINGS_ERROR':
      return {
        ...state,
        errorDet: {},
        errorMsg: '',
      };
    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
