import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import Profile from './menus/Profile';
import Search from './menus/Search';
import FullScreen from './menus/FullScreen';

import {TBProps} from '../../ts/types';

import {isCustomer} from '../../utils';

import logo from '../../static/images/logo-light.png';
import logoSmall from '../../static/images/logo-sm.png';

const App: React.FC<React.PropsWithChildren<TBProps>> = (props: TBProps) => {
  const {data} = props;

  return (
    <div className="topbar">
      <div className="topbar-left">
        <Link to="/dashboard" className="logo">
          <span>
            <img src={logo} alt="" height="35" />
          </span>
          <i>
            <img src={logoSmall} alt="" height="35" />
          </i>
        </Link>
      </div>

      <nav className="navbar-custom koloo-nav">
        <ul className="navbar-right list-inline float-right mb-0">
          {isCustomer(data) ? null : <Search />}

          <FullScreen />
          <Profile />
        </ul>

        <ul className="list-inline menu-left mb-0">
          <li className="float-left">
            <button
              className="button-menu-mobile open-left waves-effect"
              onClick={props.handleSidebar}
            >
              <i className="mdi mdi-menu"></i>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const {user} = state;

  return {
    data: user.data,
  };
};

export default connect(mapStateToProps, null)(App);
