export const initialState = {
  loading: false,
  data: {},
  errorDet: {},
  editErrorDet: {},
};

export default function customer(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_CUSTOMER':
      return {
        ...state,
        data: action.payload,
      };

    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_CUSTOMER_ERROR':
      return {
        ...state,
        errorDet: action.payload,
      };
    case 'SET_EDIT_CUSTOMER_ERROR':
      return {
        ...state,
        editErrorDet: action.payload,
      };
    case 'CLEAR_CUSTOMER_ERROR':
      return {
        ...state,
        errorDet: {},
        editErrorDet: {},
      };
    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
