import React, {lazy} from 'react';
import {Route} from 'react-router-dom';

const Transactions = lazy(() => import('../../pages/transactions/'));
const CreateUser = lazy(() => import('../../pages/create-user'));
const CreateSaving = lazy(() => import('../../pages/savings/CreateSavings2'));
const Accounts = lazy(() => import('../../pages/accounts/'));
const Profile = lazy(() => import('../../pages/accounts/Profile'));
const Contributions = lazy(() => import('../../pages/savings/Contributions'));
const Payout = lazy(() => import('../../pages/payout'));
const SingleTransactions = lazy(() =>
  import('../../pages/transactions/SingleTransactions'),
);
const TransactionPin = lazy(() => import('../../pages/transaction-pin/'));
const ContributionPage = lazy(() =>
  import('../../pages/savings/contribution-page/ContributionPage'),
);

const AgentDashboard = (props: {path: string}) => {
  const {path} = props;

  return (
    <>
      <Route exact path={`${path}/transactions`}>
        <Transactions />
      </Route>
      <Route exact path={`${path}/profile/:userId`}>
        <Profile />
      </Route>

      <Route exact path={`${path}/accounts`}>
        <Accounts />
      </Route>

      <Route exact path={`${path}/saving/create/:identity`}>
        <CreateSaving />
      </Route>
      <Route exact path={`${path}/saving/contributions/:id`}>
        <Contributions />
      </Route>
      <Route exact path={`${path}/customer`}>
        <CreateUser />
      </Route>
      <Route exact path={`${path}/accounts/commission/payouts`}>
        <Payout />
      </Route>
      <Route exact path={`${path}/transactions/:userIdentity`}>
        <SingleTransactions />
      </Route>
      <Route exact path={`${path}/set-transaction-pin`}>
        <TransactionPin />
      </Route>
      <Route
        exact
        path={`${path}/savings/contribute/:savingsId/:cycleTitle/:customerName`}
      >
        <ContributionPage />
      </Route>
    </>
  );
};

export default AgentDashboard;
