type Init = {
  createLoading: boolean;
  getLoading: boolean;
  errorDet: any;
  customerSavings: any;
  contributionsErr: any;
  contributeErr: any;
  contributionRes: any;
  contributeLoading: boolean;
  contributions: any[];
  contributionsLoading: boolean;
  errorMsg: any;

  withdrawalRes: any;
};

const initialState: Init = {
  createLoading: false,
  getLoading: false,
  errorDet: {},
  customerSavings: {},
  errorMsg: {},

  contributeErr: {},
  contributionsErr: {},
  contributionRes: {},
  contributeLoading: false,
  contributions: [],
  contributionsLoading: false,
  withdrawalRes: {},
};

export default function savings(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_SAVING':
      return {
        ...state,
        customerSavings: {
          ...state.customerSavings,
          savings: [...state.customerSavings.savings, action.payload],
        },

        errorDet: {},
      };
    case 'CLEAR_SAVINGS_ERROR':
      return {
        ...state,
        errorDet: {},
        contributeErr: {},
        errorMsg: {},
      };
    case 'GET_SAVINGS':
      return {
        ...state,
        customerSavings: action.payload,
        errorDet: {},
      };
    case 'LOADING_SAVING':
      return {
        ...state,
        createLoading: action.payload,
      };
    case 'LOADING_ALL_SAVINGS':
      return {
        ...state,
        getLoading: action.payload,
      };
    case 'SET_SAVINGS_ERROR':
      return {
        ...state,
        errorDet: action.payload,
        contributeErr: {},
      };
    case 'SET_SAVINGS_ERROR_MSG':
      return {
        ...state,
        errorMsg: action.payload,
        contributeErr: {},
        errorDet: {},
      };
    case 'SET_CONTRIBUTE_ERROR':
      return {
        ...state,
        contributeErr: action.payload,
        errorDet: {},
      };
    case 'SET_CONTRIBUTIONS_ERROR':
      return {
        ...state,
        contributeErr: action.payload,
        errorDet: {},
      };

    case 'SET_CONTRIBUTION':
      return {
        ...state,
        contributionRes: action.payload,
        contributions: [...state.contributions, action.payload.contribution],
        errorDet: {},
        contributeErr: {},
      };
    case 'SET_ALL_CONTRIBUTIONS':
      return {
        ...state,
        contributions: action.payload,
        errorDet: {},
        contributeErr: {},
      };
    case 'SET_CONTRIBUTION_LOADING':
      return {
        ...state,
        contributeLoading: action.payload,
      };
    case 'SET_CONTRIBUTIONS_LOADING':
      return {
        ...state,
        contributionsLoading: action.payload,
      };

    case 'SET_WITHDRAWAL':
      return {
        ...state,
        withdrawalRes: action.payload,
      };

    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
