import React from 'react';
import {withRouter} from 'react-router';

import TopBar from './Topbar';
import SideBar from './Sidebar';
import Footer from './Footer';
import {useLayoutContext} from '../../context/layout';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = any;

const App: React.FC<React.PropsWithChildren<Props>> = (props: Props) => {
  const {sidebar, toggleSidebar} = useLayoutContext();

  return (
    <>
      <div id="wrapper">
        <TopBar handleSidebar={toggleSidebar} />
        <SideBar toggle={sidebar} />
        <div className="content-page koloo-body">
          <div className="content">
            <div className="container-fluid">{props.children}</div>
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default withRouter(App);
