import {User} from '../../ts/types';

export const setCountries = (payload: any) => ({
  type: 'SET_COUNTRIES',
  payload,
});
export const setCountriesLoading = (payload: any) => ({
  type: 'SET_COUNTRIES_LOADING',
  payload,
});
export const setCountryId = (payload: any) => ({
  type: 'SET_COUNTRY_ID',
  payload,
});
export const setStates = (payload: any) => ({
  type: 'SET_STATES',
  payload,
});
export const addNewState = (payload: any) => ({
  type: 'ADD_NEW_STATE',
  payload,
});
export const addNewCountry = (payload: any) => ({
  type: 'ADD_NEW_COUNTRY',
  payload,
});

export const setAgent = (
  payload: {} | User,
): {type: string; payload: {} | User} => ({
  type: 'SET_AGENT',
  payload,
});

export const setLoading = (payload: boolean) => ({
  type: 'LOADING',
  payload,
});
export const setLoginLoading = (payload: boolean) => ({
  type: 'SET_LOGIN_LOADING',
  payload,
});
export const setFormLoading = (payload: boolean) => ({
  type: 'SET_FORM_LOADING',
  payload,
});
export const setSavingCycleLoading = (payload: boolean) => ({
  type: 'LOADING_SAVING_CYCLE',
  payload,
});
export const setSavingLoading = (payload: boolean) => ({
  type: 'LOADING_SAVING',
  payload,
});
export const setAllSavingsLoading = (payload: boolean) => ({
  type: 'LOADING_ALL_SAVINGS',
  payload,
});
export const setAllSavingCycleLoading = (payload: boolean) => ({
  type: 'LOADING_ALL_SAVING_CYCLE',
  payload,
});

export const setCustomerErrorDet = (payload: string) => ({
  type: 'SET_CUSTOMER_ERROR',
  payload,
});
export const setEditCustomerErrorDet = (payload: string) => ({
  type: 'SET_EDIT_CUSTOMER_ERROR',
  payload,
});

export const setSavingsError = (payload: any) => ({
  type: 'SET_SAVINGS_ERROR',
  payload,
});
export const setSavingsErrorMsg = (payload: any) => ({
  type: 'SET_SAVINGS_ERROR_MSG',
  payload,
});
export const setAgentErrorDet = (payload: string) => ({
  type: 'SET_AGENT_ERROR',
  payload,
});
export const setLoginError = (payload: string) => ({
  type: 'SET_LOGIN_ERROR',
  payload,
});
export const clearLoginError = () => ({
  type: 'CLEAR_LOGIN_ERROR',
});
export const setOtpError = (payload: string) => ({
  type: 'SET_OTP_ERROR',
  payload,
});
export const setPasswordResetError = (payload: any) => ({
  type: 'SET_PASSWORD_REST_ERROR',
  payload,
});
export const setCountryErrorDet = (payload: string) => ({
  type: 'SET_COUNTRY_ERROR',
  payload,
});
export const setStateError = (payload: string) => ({
  type: 'SET_STATE_ERROR',
  payload,
});
export const setSavingCycleErrorDet = (payload: string) => ({
  type: 'SET_SAVING_CYCLE_ERROR',
  payload,
});
export const clearSavingsError = () => ({
  type: 'CLEAR_SAVINGS_ERROR',
});

export const setUser = (
  payload: {} | User,
): {type: string; payload: {} | User} => ({
  type: 'SET_USER',
  payload,
});
export const setCustomer = (payload: any) => ({
  type: 'SET_CUSTOMER',
  payload,
});
export const setSavingCycle = (payload: any) => ({
  type: 'SET_SAVING_CYCLE',
  payload,
});
export const setSaving = (payload: any) => ({
  type: 'SET_SAVING',
  payload,
});
export const clearSavingCycleError = () => ({
  type: 'CLEAR_SAVING_CYCLE_ERROR',
});
export const clearAgentError = () => ({
  type: 'CLEAR_AGENT_ERROR',
});
export const clearCustomerError = () => ({
  type: 'CLEAR_CUSTOMER_ERROR',
});

export const setUploadError = (payload: any) => ({
  type: 'SET_UPLOAD_ERROR',
  payload,
});
export const allSavingCycle = (payload: any[]) => ({
  type: 'ALL_SAVING_CYCLE',
  payload,
});
export const getSavings = (payload: any) => ({
  type: 'GET_SAVINGS',
  payload,
});

export const setToken = (payload: string) => ({
  type: 'SET_TOKEN',
  payload,
});

export const signout = () => ({
  type: 'LOG_OUT',
});

export const setProfile = (payload: any) => ({
  type: 'SET_PROFILE',
  payload,
});
export const setSingleProfile = (payload: any) => ({
  type: 'SET_SINGLE_PROFILE',
  payload,
});

/*
 * =================
 * BALANCE
 * =================
 */

export const setBalance = (payload: any) => ({
  type: 'SET_BALANCE',
  payload,
});

export const setBalanceLoading = (payload: boolean) => ({
  type: 'BALANCE_LOADING',
  payload,
});

export const clearBalanceError = () => ({
  type: 'CLEAR_BALANCE_ERROR',
});

export const setBalanceError = (payload: any) => ({
  type: 'SET_BALANCE_ERROR',
  payload,
});

export const setBalErrorMessage = (payload: any) => ({
  type: 'SET_BAL_ERROR_MESSAGE',
  payload,
});

/**
 * ==============
 * DEBIT/CREDIT
 * ==============
 */

export const setDebitCreditLoading = (payload: boolean) => ({
  type: 'DEBIT_CREDIT_LOADING',
  payload,
});

export const setDebitCreditRes = (payload: any) => ({
  type: 'DEBIT_CREDIT_RESPONSE',
  payload,
});

export const setDebitCreditErr = (payload: any) => ({
  type: 'DEBIT_CREDIT_ERROR',
  payload,
});
export const setDebitCreditErrMsg = (payload: any) => ({
  type: 'DEBIT_CREDIT_ERROR_MSG',
  payload,
});
export const clearDCError = () => ({
  type: 'CLEAR_DC_ERROR',
});

/*
 * =================
 * ACCOUNTS
 * =================
 */
export const setAccounts = (payload: any) => ({
  type: 'SET_ACCOUNTS',
  payload,
});
export const setSingleAccount = (payload: any) => ({
  type: 'SET_SINGLE_ACCOUNT',
  payload,
});

export const setSingleAccountLoading = (payload: boolean) => ({
  type: 'SINGLE_ACCOUNT_LOADING',
  payload,
});
export const setAccountsLoading = (payload: boolean) => ({
  type: 'ACCOUNTS_LOADING',
  payload,
});

export const clearAccountsError = () => ({
  type: 'CLEAR_ACCOUNTS_ERROR',
});

export const setAccountsError = (payload: any) => ({
  type: 'SET_ACCOUNTS_ERROR',
  payload,
});
export const setStatsError = (payload: any) => ({
  type: 'SET_STATS_ERROR',
  payload,
});
export const setStats = (payload: any) => ({
  type: 'SET_STATS',
  payload,
});

/*
 * =================
 * TRANSACTIONS
 * =================
 */

export const setTransactions = (payload: any) => ({
  type: 'SET_TRANSACTIONS',
  payload,
});

export const setTransactionsLoading = (payload: boolean) => ({
  type: 'TRANSACTIONS_LOADING',
  payload,
});

export const clearTransactionsError = () => ({
  type: 'CLEAR_TRANSACTIONS_ERROR',
});

export const setTransactionsError = (payload: any) => ({
  type: 'SET_TRANSACTIONS_ERROR',
  payload,
});

/*
 * =================
 * CONTRIBUTE
 * =================
 */

export const setContribution = (payload: any) => ({
  type: 'SET_CONTRIBUTION',
  payload,
});

export const setContributionLoading = (payload: boolean) => ({
  type: 'SET_CONTRIBUTION_LOADING',
  payload,
});

export const setContributionError = (payload: any) => ({
  type: 'SET_CONTRIBUTE_ERROR',
  payload,
});
/*
 * =================
 * CONTRIBUTIONS
 * =================
 */

export const setAllContributions = (payload: any) => ({
  type: 'SET_ALL_CONTRIBUTIONS',
  payload,
});

export const setContributionsLoading = (payload: boolean) => ({
  type: 'SET_CONTRIBUTIONS_LOADING',
  payload,
});

export const setContributionsError = (payload: any) => ({
  type: 'SET_CONTRIBUTIONS_ERROR',
  payload,
});

/** UPLOAD AGREEMENT LOADING */

export const setPassportLoading = (payload: boolean) => ({
  type: 'PASSPORT_LOADING',
  payload,
});
export const setAgreementtLoading = (payload: boolean) => ({
  type: 'AGREEMENT_LOADING',
  payload,
});
export const setEditLoading = (payload: boolean) => ({
  type: 'SET_EDIT_LOADING',
  payload,
});
export const setApplicationFormLoading = (payload: boolean) => ({
  type: 'APPLICATION_FORM_LOADING',
  payload,
});

export const setPassportError = (payload: any) => ({
  type: 'SET_PASSPORT_ERROR',
  payload,
});

export const setAgreementError = (payload: any) => ({
  type: 'SET_AGREEMENT_ERROR',
  payload,
});
export const setApplicationFormError = (payload: any) => ({
  type: 'SET_APPLICATION_FORM_ERROR',
  payload,
});

/** APPROVE AGENT */

export const setPending = (payload: any) => ({
  type: 'SET_PENDING',
  payload,
});
export const setLoadingPending = (payload: boolean) => ({
  type: 'LOADING_ALL_PENDING',
  payload,
});
export const setLoadingApprove = (payload: boolean) => ({
  type: 'LOADING_APPROVE',
  payload,
});
export const setPendingError = (payload: boolean) => ({
  type: 'SET_PENDING_ERROR',
  payload,
});
export const setApproveError = (payload: boolean) => ({
  type: 'SET_APPROVE_ERROR',
  payload,
});

export const clearApprovalError = () => ({
  type: 'CLEAR_APPROVAL_ERROR',
});
export const setSuspendError = (payload: boolean) => ({
  type: 'SET_SUSPEND_ERROR',
  payload,
});
export const setSuspendLoading = (payload: boolean) => ({
  type: 'SET_LOADING_SUSPEND',
  payload,
});

/** Commission */

export const setCommission = (payload: any) => ({
  type: 'SET_COMMISSION_RES',
  payload,
});
export const setAllCommission = (payload: any) => ({
  type: 'SET_COMMISSIONS',
  payload,
});
export const setCommissionLoading = (payload: boolean) => ({
  type: 'SET_COMMISSIONS_LOADING',
  payload,
});
export const setGetCommissionLoading = (payload: boolean) => ({
  type: 'SET_GET_COMMISSIONS_LOADING',
  payload,
});
export const clearCommissionError = () => ({
  type: 'CLEAR_COMMISSION_ERRORS',
});
export const setCommissionError = (payload: any) => ({
  type: 'SET_COMMISSION_ERRORS',
  payload,
});
export const setCommissionOtpError = (payload: any) => ({
  type: 'SET_COMMISSION_OTP_ERRORS',
  payload,
});

/** BANKS */

export const setBanks = (payload: any) => ({
  type: 'SET_BANKS',
  payload,
});
export const setBanksLoading = (payload: boolean) => ({
  type: 'SET_BANKS_LOADING',
  payload,
});
export const setAddBankLoading = (payload: boolean) => ({
  type: 'SET_ADD_BANK_LOADING',
  payload,
});
export const enableBankLoading = (payload: boolean) => ({
  type: 'ENABLE_BANK_LOADING',
  payload,
});

export const clearBankError = () => ({
  type: 'CLEAR_BANK_ERROR',
});

export const setBanksError = (payload: any) => ({
  type: 'SET_BANKS_ERROR',
  payload,
});

export const setNewBank = (payload: any) => ({
  type: 'SET_NEW_BANK',
  payload,
});

export const setEnableBank = (payload: any) => ({
  type: 'ENABLE_BANK',
  payload,
});

/** WITHDRAWAL */

export const setWithdrawal = (payload: any) => ({
  type: 'SET_WITHDRAWAL',
  payload,
});

/** Request New Password */

export const setReqNewPasswordLoading = (payload: any) => ({
  type: 'SET_REQ_NEW_PASSWORD_LOADING',
  payload,
});
export const setReqNewPasswordError = (payload: any) => ({
  type: 'SET_REQ_NEW_PASSWORD_ERROR',
  payload,
});

/** Settings */

export const setSettings = (payload: any) => ({
  type: 'SET_SETTINGS',
  payload,
});
export const setSettingsLoading = (payload: any) => ({
  type: 'SET_SETTINGS_LOADING',
  payload,
});
export const setGetSettingsLoading = (payload: any) => ({
  type: 'SET_GET_SETTINGS_LOADING',
  payload,
});
export const setSettingsErrors = (payload: any) => ({
  type: 'SET_SETTINGS_ERRORS',
  payload,
});
export const setSettingsErrorMsg = (payload: any) => ({
  type: 'SET_SETTINGS_ERROR_MSG',
  payload,
});
export const clearSettingsError = () => ({
  type: 'CLEAR_SETTINGS_ERROR',
});

/** GET ALL SUPER AGENTS */

export const setSuperAgentLoading = (payload: any) => ({
  type: 'SET_SUPER_AGENTS_LOADING',
  payload,
});
export const setSuperAgents = (payload: any) => ({
  type: 'SET_SUPER_AGENTS',
  payload,
});
export const setSuperAgentsError = (payload: any) => ({
  type: 'SET_SUPER_AGENTS_ERRORS',
  payload,
});
