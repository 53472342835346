const initialState = {
  loading: false,
  data: {},
  errorDet: {},
  errorMsg: '',
};

export default function fund(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_BALANCE':
      return {
        ...state,
        errorDet: {},
        data: action.payload,
      };

    case 'BALANCE_LOADING':
      return {
        ...state,
        loading: action.payload,
      };

    case 'CLEAR_BALANCE_ERROR':
      return {
        ...state,
        errorDet: {},
        errorMsg: '',
      };

    case 'SET_BALANCE_ERROR':
      return {
        ...state,
        errorDet: action.payload,
        errorMsg: {},
      };
    case 'SET_BAL_ERROR_MESSAGE':
      return {
        ...state,
        errorMsg: action.payload,
        errorDet: {},
      };
    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
