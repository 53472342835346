import React from 'react';
import {Link} from 'react-router-dom';

declare global {
  interface Document {
    exitFullscreen: () => Promise<void>;
    mozCancelFullScreen: () => void;
    webkitExitFullscreen: () => void;
    fullscreenElement: () => void;
    mozFullScreenElement: () => void;
    webkitFullscreenElement: () => void;
  }

  interface HTMLElement {
    msRequestFullscreen?: () => Promise<void>;
    mozRequestFullscreen?: () => Promise<void>;
    webkitRequestFullscreen?: () => Promise<void>;
  }
}

const App = () => {
  const toggleFullscreen = () => {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullscreen) {
        document.documentElement.mozRequestFullscreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement
          .webkitRequestFullscreen
          // Element.ALLOW_KEYBOARD_INPUT,
          ();
      }
    } else {
      if (document.exitFullscreen()) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  };

  return (
    <li className="dropdown notification-list list-inline-item d-none d-md-inline-block">
      <Link
        className="nav-link waves-effect"
        id="btn-fullscreen"
        onClick={toggleFullscreen}
        to="#"
      >
        <i className="mdi mdi-fullscreen noti-icon"></i>
      </Link>
    </li>
  );
};

export default App;
