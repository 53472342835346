import React, {lazy, Suspense} from 'react';
import {Route, Switch, useRouteMatch} from 'react-router-dom';
import {connect} from 'react-redux';

import Layout from '../../components/layout';
import AdminDashboard from './AdminDashboard';
import SuperagentDashboard from './SuperagentDashboard';
import AgentDashboard from './AgentDashboard';
import CustomerDashboard from './CustomerDashboard';
import Loading from '../../components/loading/Loading';

import {isAdmin, isSuperAgent, isAgent, isSuperUser, isCustomer} from '../../utils/';

const ProtectedRoutes = lazy(() => import('../../components/private-routes'));

const Transactions = lazy(() =>
  import('../../pages/transactions/Transactions'),
);
const Dashboard = lazy(() => import('../../pages/dashboard'));

type DashboardProps = {
  data?: any;
};

const App: React.FC<React.PropsWithChildren<DashboardProps>> = (
  props: DashboardProps,
) => {
  const {path} = useRouteMatch();
  const {data} = props;

  return (
    <ProtectedRoutes>
      <Layout>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route exact path={`${path}`}>
              <Dashboard />
            </Route>

            {isAdmin(data) ? (
              <AdminDashboard path={path} />
            ) : isSuperAgent(data) ? (
              <SuperagentDashboard path={path} />
            ) : isAgent(data) ? (
              <AgentDashboard path={path} />
            ) : isCustomer(data) ? (
              <CustomerDashboard path={path} />
            ): isSuperUser(data) ? (
              <Route exact path={`${path}`}>
                <Transactions />
              </Route>
            ) : (
              <Route exact path={`${path}/transactions`}>
                <Transactions />
              </Route>
            )}
          </Switch>
        </Suspense>
      </Layout>
    </ProtectedRoutes>
  );
};

const mapStateToProps = (state: any) => {
  const {user} = state;

  return {
    data: user.data,
  };
};

export default connect(mapStateToProps, null)(App);
