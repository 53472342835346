import React from 'react';
import {Link, useLocation} from 'react-router-dom';

const App = (props: {
  item: any;
  currIndex: any;
  handleClick: any;
  index: any;
}) => {
  const currentPath = useLocation().pathname;
  const {item, currIndex, handleClick, index} = props;
  const icons = [
    'ti-home',
    'ti-calendar',
    'ti-receipt',
    'ti-archive',
    'ti-calendar',
    'ti-support',
    'ti-write',
  ];
  let text = Object.keys(item)[0];
  let to = item[text];

  return (
    <>
      <li className={currIndex === index ? 'mm-active' : ''}>
        <Link
          to={to}
          className={`waves-effect ${currentPath === to ? 'mm-active' : ''}`}
          onClick={() => handleClick(index)}
        >
          <i className={icons[index]}></i>
          <span> {text} </span>
        </Link>
      </li>
    </>
  );
};

export default App;
