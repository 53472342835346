const initialState = {
  superAgentLoading: false,
  data: [],
  superAgentsErrors: {},
};

export default function superAgents(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_SUPER_AGENTS_ERRORS':
      return {
        ...state,
        superAgentsErrors: action.payload,
      };
    case 'SET_SUPER_AGENTS_LOADING':
      return {
        ...state,
        superAgentLoading: action.payload,
      };
    case 'SET_SUPER_AGENTS':
      return {
        ...state,
        data: action.payload,
      };
    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
