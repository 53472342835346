/* eslint-disable react/prop-types */
import React from 'react';

import {Provider} from 'react-redux';
import {ErrorBoundary} from 'react-error-boundary';

import {LayoutProvider} from './context/layout';
import Root from './Root';
import ErrorFallback from './ErrorBoundary';
import store from './store';

const App = props => {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location = '/dashboard';
      }}
    >
      <Provider store={store}>
        <LayoutProvider>
          <Root />
        </LayoutProvider>
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
