import {combineReducers} from 'redux';
import user from './redux/reducers/user.reducer';
import authentication from './redux/reducers/token.reducer';
import agent from './redux/reducers/agent.reducer';
import countries from './redux/reducers/countries.reducer';
import customer from './redux/reducers/customer.reducer';
import savingCycles from './redux/reducers/saving-cycles.reducer';
import savings from './redux/reducers/savings.reducer';
import fund from './redux/reducers/fund.reducer';
import accounts from './redux/reducers/accounts.reducer';
import transactions from './redux/reducers/transactions.reducer';
import approvals from './redux/reducers/approvals.reducer';
import commission from './redux/reducers/commission.reducer';
import banks from './redux/reducers/bank.reducer';
import settings from './redux/reducers/settings.reducer';
import superAgents from './redux/reducers/superAgents.reducer';
import allSavings from './redux/reducers/allSavings.reducer';
import transactionPin from './redux/reducers/transaction-pin.reducer';
import debitCredit from './redux/reducers/debitCredit.reducer';

export default combineReducers({
  user,
  savingCycles,
  authentication,
  agent,
  countries,
  customer,
  savings,
  fund,
  accounts,
  transactions,
  approvals,
  commission,
  banks,
  settings,
  superAgents,
  allSavings,
  transactionPin,
  debitCredit,
});
