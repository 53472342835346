import React from 'react';
import {Redirect, useLocation} from 'react-router-dom';

const App: React.FC = () => {
  const location = useLocation();
  const currentLocation =
    location.pathname === '/' ? '/dashboard' : location.pathname;

  return <Redirect to={{pathname: currentLocation}} />;
};

export default App;
