import React, {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

import {useLayoutContext} from '../../context/layout';

const App = (props: {
  item: any;
  currIndex: number;
  index: number;
  setCurrIndex: any;
}) => {
  const [open, setOpen] = useState(false);

  const currentPath = useLocation().pathname;

  const {closeSidebar, isMobile} = useLayoutContext();

  const {item, index, setCurrIndex} = props;

  const MenuName = Object.keys(item)[0];

  useEffect(() => {
    const isAdminPath = currentPath.includes('/admin/');

    if (isAdminPath) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [currentPath]);

  const handleClick = () => {
    setOpen(prevState => !prevState);
    setCurrIndex(index);
  };

  const handleCollapseNav = () => {
    setCurrIndex(index);
    if (isMobile) {
      closeSidebar();
    }
  };

  return (
    <>
      <li className={currentPath.includes('/admin/') ? 'mm-active' : ''}>
        <Link
          onClick={handleClick}
          to="#"
          className={
            open
              ? `waves-effect ${
                  currentPath.includes('/admin/') ? 'mm-active' : ''
                }`
              : 'waves-effect mm-collapsed'
          }
          aria-expanded={open}
        >
          <i className="ti-face-smile"></i>
          <span>
            {MenuName}
            <span className="float-right menu-arrow">
              <i className="mdi mdi-chevron-right"></i>
            </span>{' '}
          </span>
        </Link>
        <ul
          className={
            open ? 'submenu mm-collapse mm-show' : 'submenu mm-collapse'
          }
        >
          {item[MenuName].map((tab: any, index: number) => {
            let text = Object.keys(tab)[0];
            let to = tab[text];

            return (
              <li onClick={handleCollapseNav} key={`collapsible-${index}`}>
                <Link to={to}>{text}</Link>
              </li>
            );
          })}
        </ul>
      </li>
    </>
  );
};

export default App;
