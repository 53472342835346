const initialState = {
  loading: false,
  data: [],
  errorDet: {},
  addBankLoading: false,
  enableLoading: false,
};

const modifyBank = (data: any, payload: any) => {
  return data.map((bank: any) => {
    if (bank.name === payload.name) {
      return payload;
    }
    return bank;
  });
};

export default function banks(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_BANKS':
      return {
        ...state,
        errorDet: {},
        data: action.payload,
      };
    case 'SET_NEW_BANK':
      return {
        ...state,
        errorDet: {},
        data: [...state.data, action.payload],
      };

    case 'ENABLE_BANK':
      return {
        ...state,
        data: modifyBank(state.data, action.payload),
      };
    case 'ENABLE_BANK_LOADING':
      return {
        ...state,
        enableLoading: action.payload,
      };

    case 'SET_BANKS_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_ADD_BANK_LOADING':
      return {
        ...state,
        addBankLoading: action.payload,
      };

    case 'CLEAR_BANK_ERROR':
      return {
        ...state,
        errorDet: {},
      };

    case 'SET_BANKS_ERROR':
      return {
        ...state,
        errorDet: action.payload,
      };

    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
