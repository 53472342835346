import request from '../../utils/request';
import {AppDispatch} from '../../store';
import {
  setUser,
  setToken,
  setLoginLoading,
  setLoginError,
  clearLoginError,
} from './actionCreators';

const loginBoundActionCreator = (
  credentials: {},
  navigateToDashboard: any,
  openNotification: any,
) => async (dispatch: AppDispatch) => {
  try {
    dispatch(clearLoginError());
    dispatch(setLoginLoading(true));
    const response = await request.post('/auth/login', credentials);

    if (response.data.data.otp_required) {
      dispatch(setLoginLoading(false));
      return;
    }

    dispatch(setUser(response.data.data.user));

    dispatch(setToken(response.data.data.access_token));
    dispatch(setLoginLoading(false));
    dispatch(clearLoginError());
    navigateToDashboard();
    return response.data;
  } catch (error) {
    dispatch(setLoginLoading(false));

    if (error.response) {
      dispatch(setLoginError(error.response.data.message));
      openNotification(error.response.data.message);
    } else if (error.request) {
      dispatch(setLoginError('Please Try again'));
      openNotification('Please Try again');
    } else {
      dispatch(setLoginError('Process Failed!'));
      openNotification('Process Failed!');
    }
    // console.log(error.config);
    dispatch(setLoginLoading(false));
  }
};

export default loginBoundActionCreator;
