type Init = {
  allPending: any[];
  pendingErrorDet: any;
  getLoading: boolean;
  approveLoading: boolean;
  approveErrorDet: any;
  suspendAccLoading: boolean;
  suspendAccErrorDet: any;
};

const initialState: Init = {
  allPending: [],
  pendingErrorDet: {},
  getLoading: false,
  approveLoading: false,
  approveErrorDet: {},
  suspendAccLoading: false,
  suspendAccErrorDet: {},
};

export default function approvals(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_PENDING':
      return {
        ...state,
        allPending: action.payload,
        pendingErrorDet: {},
      };
    case 'CLEAR_APPROVAL_ERROR':
      return {
        ...state,
        pendingErrorDet: {},
        suspendAccErrorDet: {},
      };

    case 'LOADING_ALL_PENDING':
      return {
        ...state,
        getLoading: action.payload,
      };
    case 'LOADING_APPROVE':
      return {
        ...state,
        approveLoading: action.payload,
      };
    case 'SET_LOADING_SUSPEND':
      return {
        ...state,
        suspendAccLoading: action.payload,
      };
    case 'SET_SUSPEND_ERROR':
      return {
        ...state,
        suspendAccErrorDet: action.payload,
      };
    case 'SET_PENDING_ERROR':
      return {
        ...state,
        pendingErrorDet: action.payload,
        approveErrorDet: {},
      };
    case 'SET_APPROVE_ERROR':
      return {
        ...state,
        approveErrorDet: action.payload,
        pendingErrorDet: {},
      };

    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
