const initialState = {
  loading: false,
  getCommLoading: false,
  data: {},
  errorDet: {},
  errorMsg: '',
  commissions: {},
};

export default function commission(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_COMMISSION_RES':
      return {
        ...state,
        errorDet: {},
        commissions: action.payload,
      };
    case 'SET_COMMISSIONS':
      return {
        ...state,
        errorDet: {},
        data: action.payload,
      };
    case 'SET_COMMISSIONS_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_GET_COMMISSIONS_LOADING':
      return {
        ...state,
        getCommLoading: action.payload,
      };
    case 'CLEAR_COMMISSION_ERRORS':
      return {
        ...state,
        errorDet: {},
        errorMsg: '',
      };
    case 'SET_COMMISSION_ERRORS':
      return {
        ...state,
        errorDet: action.payload,
        errorMsg: '',
      };
    case 'SET_COMMISSION_OTP_ERRORS':
      return {
        ...state,
        errorDet: '',
        errorMsg: action.payload,
      };

    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
