type Cycle = {
  [key: string]: string;
};

type Init = {
  loading: boolean;
  getSavingLoading: boolean;
  errorDet: any;
  data: any;
  allSavings: Cycle[];
};

export const initialState: Init = {
  loading: false,
  getSavingLoading: false,
  errorDet: {},
  allSavings: [],
  data: {},
};

export default function savingCycles(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_SAVING_CYCLE':
      return {
        ...state,
        allSavings: [...state.allSavings, action.payload],
        data: action.payload,
        errorDet: {},
      };
    case 'CLEAR_SAVING_CYCLE_ERROR':
      return {
        ...state,
        errorDet: {},
      };

    case 'ALL_SAVING_CYCLE':
      return {
        ...state,
        allSavings: action.payload,
        errorDet: {},
      };

    case 'LOADING_SAVING_CYCLE':
      return {
        ...state,
        loading: action.payload,
      };
    case 'LOADING_ALL_SAVING_CYCLE':
      return {
        ...state,
        getSavingLoading: action.payload,
      };
    case 'SET_SAVING_CYCLE_ERROR':
      return {
        ...state,
        errorDet: action.payload,
      };
    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
