import React, {useState, useLayoutEffect} from 'react';

import {createCtx} from '../CreateCtx';

type LayoutType = {
  sidebar: boolean;
  isMobile: boolean;
  toggleSidebar: () => void;
  closeSidebar: () => void;
};

const [useLayoutContext, CtxProvider] = createCtx<LayoutType>();

function LayoutProvider(props: React.PropsWithChildren<unknown>) {
  const layoutData = useProvideLayout();

  return <CtxProvider value={layoutData}>{props.children}</CtxProvider>;
}

function useProvideLayout(): LayoutType {
  const [isMobile, setIsMobile] = useState(false);
  const [sidebar, setSidebar] = React.useState(false);

  const toggleSidebar = () => {
    const nextState = sidebar === true ? false : true;
    setSidebar(nextState);
  };

  const handleLayout = () => {
    const breakpoint = '(max-width: 1100px)';

    const matchList = window.matchMedia(breakpoint);

    if (matchList.matches) {
      setIsMobile(true);
      setSidebar(false);
    } else {
      setIsMobile(false);
      setSidebar(true);
    }

    return matchList;
  };

  useLayoutEffect(() => {
    const matchListener = handleLayout();

    if (window) {
      matchListener.addListener(handleLayout);
    }

    return () => matchListener.removeListener(handleLayout);
  }, []);

  useLayoutEffect(() => {
    if (!sidebar) {
      document.body.classList.add('enlarged');
    } else {
      document.body.classList.remove('enlarged');
    }
  }, [sidebar]);

  const closeSidebar = () => {
    setSidebar(false);
  };

  return {isMobile, toggleSidebar, closeSidebar, sidebar};
}

export {LayoutProvider, useLayoutContext};
