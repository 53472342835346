export const initialState = {
  loading: false,
  passportLoading: false,
  agreementLoading: false,
  applicatioFormLoading: false,
  data: {},
  errorDet: {},
  passportErr: {},
  agreementErr: {},
  applicatioFormErr: {},
  editLoading: false,
};

export default function agent(state = initialState, action: any) {
  switch (action.type) {
    case 'SET_AGENT':
      return {
        ...state,
        errorDet: {},
        data: {...state.data, ...action.payload},
      };

    case 'LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SET_EDIT_LOADING':
      return {
        ...state,
        editLoading: action.payload,
      };
    case 'PASSPORT_LOADING':
      return {
        ...state,
        passportLoading: action.payload,
      };
    case 'AGREEMENT_LOADING':
      return {
        ...state,
        agreementLoading: action.payload,
      };
    case 'APPLICATION_FORM_LOADING':
      return {
        ...state,
        applicatioFormLoading: action.payload,
      };

    case 'CLEAR_AGENT_ERROR':
      return {
        ...state,
        errorDet: {},
        uploadErr: {},
      };
    case 'SET_AGENT_ERROR':
      return {
        ...state,
        errorDet: action.payload,
      };
    case 'SET_PASSPORT_ERROR':
      return {
        ...state,
        passportErr: action.payload,
      };
    case 'SET_AGREEMENT_ERROR':
      return {
        ...state,
        agreementErr: action.payload,
      };
    case 'SET_APPLICATION_FORM_ERROR':
      return {
        ...state,
        applicatioFormErr: action.payload,
      };
    case 'LOG_OUT':
      return initialState;

    default:
      return state;
  }
}
